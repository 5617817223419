import React from "react";

const AgendarSesionZoho = () => {
  const correoUsuario = localStorage.getItem("email");

  return (
    <div className="agendarSesion-container">
      <iframe
        id="myZohoBookings"
        style={{ borderRadius: "1vw" }}
        width="100%"
        height="550px"
        src={`https://techfinerik33.zohobookings.com/#/4624677000000366008?Email=${correoUsuario}&disabled=true`}
        // src={`https://techfinerik33.zohobookings.com/portal-embed#/finerikgroup?Email=${correoUsuario}&disabled=true`}
        allowfullscreen=""
        title="ZohoBookings"
      ></iframe>
    </div>
  );
};

export default AgendarSesionZoho;
