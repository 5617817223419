import React from "react";
import { FileText } from "lucide-react";
import { Label } from "@/components/ui/label";

const DocumentItemList = (props) => {
  const { docsList, docsStatus } = props;
  return (
    <div
      className="doc-view mb-2"
      style={
        docsStatus === "Pendiente"
          ? { border: "0.1vw solid #4944FC" }
          : docsStatus === "Aprobado"
          ? { border: "0.1vw solid #73dc6f" }
          : docsStatus === "Rechazado"
          ? { border: "0.1vw solid #FF3366" }
          : {}
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <FileText
          style={{ color: "#415575", margin: "0vw 0.3vw" }}
          size={16}
        ></FileText>
        <Label>{docsList.userFileName}</Label>
      </div>
    </div>
  );
};

DocumentItemList.propTypes = {};

export default DocumentItemList;
