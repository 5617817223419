import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { X } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import LeasingIcon from "@/assets/icons/leasing.png";
import CrecimientoIcon from "@/assets/icons/crecimiento.png";
import FinanciamientoIcon from "@/assets/icons/financiamento.png";

const ViewLeasing = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center min-h-screen w-full bg-white p-4 flex-col">
      <div>
        <div className="w-full flex justify-end pr-4 py-3">
          <X
            className="cursor-pointer"
            onClick={() => {
              navigate("/on-boarding");
            }}
          ></X>
        </div>
        <div className="w-full grid grid-cols-12 gap-x-4 gap-y-4 px-4">
          <Card className="col-span-12 lg:col-span-4 p-4">
            <div className="image-container">
              <img className="h-20" src={LeasingIcon} alt="leasing"></img>
            </div>
            <div className="flex flex-col gap-y-4 ">
              <Label className="text-2xl lg:text-2xl text-center">
                ¿Qué es leasing ?
              </Label>
              <Label className="text-sm lg:text-md text-center">
                El leasing es como un alquiler a largo plazo. Puedes usar cosas
                importantes para tu negocio sin tener que comprarlas de
                inmediato. Es una forma flexible de obtener lo que necesitas y
                pagar mensualmente.
              </Label>
            </div>
          </Card>
          <Card className="col-span-12 lg:col-span-4 p-4">
            <div className="image-container">
              <img
                className="h-20"
                src={CrecimientoIcon}
                alt="leasing icon 2"
              ></img>
            </div>
            <div className="flex flex-col gap-y-4 ">
              <Label className="text-2xl lg:text-2xl text-center">
                Crecimiento
              </Label>
              <Label className="text-sm lg:text-md text-center">
                Con el leasing, puedes acceder a equipos y recursos clave para
                impulsar el crecimiento de tu empresa. Obtén lo que necesitas
                para expandirte y tener más éxito sin gastar todo tu dinero de
                una vez.
              </Label>
            </div>
          </Card>
          <Card className="col-span-12 lg:col-span-4 p-4">
            <div className="image-container">
              <img
                className="h-20"
                src={FinanciamientoIcon}
                alt="leasing icon 3"
              ></img>
            </div>
            <div className="flex flex-col gap-y-4 ">
              <Label className="text-2xl lg:text-2xl text-center">
                Financiamiento
              </Label>
              <Label className="text-sm lg:text-md text-center">
                El leasing te brinda una opción de financiamiento conveniente.
                En lugar de pagar todo de una vez, puedes distribuir los pagos
                en el tiempo. Así puedes administrar mejor tu flujo de efectivo
                y tener el capital necesario para tus operaciones.
              </Label>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ViewLeasing;
