import React, { useEffect } from "react";
import { FileUploader } from "@/components/file-uploader";
import { Button } from "@/components/ui/button";

const CustomFileUploader = (props) => {
  const { handleChange, setUploadedFiles, uploadedFiles = [] } = props;

  function translateText(text) {
    if (text === "Uploaded Successfully!  Upload another?") {
      return "¡Cargado exitosamente! ¿Subir otro?";
    } else {
      return text;
    }
  }

  useEffect(() => {
    const translateUploadedMessage = () => {
      const uploadedMessageElements =
        document.querySelectorAll(".sc-fqkvVR.kFhUBM");
      uploadedMessageElements.forEach((element) => {
        const englishText = element.textContent.trim();
        const translatedText = translateText(englishText);
        element.textContent = translatedText;
      });
    };

    const handleFileUploadSuccess = () => {
      translateUploadedMessage();
    };

    document.addEventListener("fileUploaded", handleFileUploadSuccess);
    return () => {
      document.removeEventListener("fileUploaded", handleFileUploadSuccess);
    };
  }, [uploadedFiles]);

  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <FileUploader
          multiple
          maxFileCount={6}
          onValueChange={setUploadedFiles}
          value={uploadedFiles}
        />
        {uploadedFiles.length > 0 && (
          <div className="my-3">
            <Button onClick={() => handleChange()}>Subir Archivos</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomFileUploader;
