import { Dialog, DialogContent } from "@/components/ui/dialog";

const Modal = ({ open, setOpen, children }) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
