import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import RejectIcon from "@/assets/icons/rechazado.png";

const RejectRequest = (props) => {
  const navigate = useNavigate();

  return (
    <Card>
      <div className="p-4 flex flex-col items-center justify-center max-w-[28rem]">
        <img className="h-32" src={RejectIcon} alt="reject icon"></img>
        <Label className="text-3xl text-center my-2 font-semibold">
          ¡Gracias por haber aplicado!
        </Label>
        <Label className="my-2 text-center text-md mb-4">
          Agradecemos mucho tu interés en este producto. Hemos analizado las
          respuestas de tu aplicación y lamentamos informarte que por el momento
          no podremos ayudarte con tu solicitud. Esperamos trabajar muy pronto
          juntos.
        </Label>

        <Button
          onClick={() => {
            localStorage.clear();
            navigate("/auth/login");
          }}
        >
          Finalizar
        </Button>
      </div>
    </Card>
  );
};

export default RejectRequest;
