import React, { useState } from "react";
import { Eye, EyeOff, HelpCircle } from "lucide-react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";

const InputPassword = ({
  label,
  withTooltip,
  tooltipText,
  icon,
  withSpecs,
  type,
  value,
  classNameContainer = "",
  error = "",
  ...props
}) => {
  const [show, setShow] = useState(false);
  const togglePasswordVisibility = () => {
    setShow(!show);
  };

  return (
    <>
      {label && (
        <Label>
          {label}
          {withTooltip && (
            <HelpCircle
              data-tooltip-id="my-tooltip"
              data-tooltip-content={tooltipText}
              className="help-icon2"
              fill="#1A222F"
              color="white"
            />
          )}
        </Label>
      )}
      <div className={`relative ${classNameContainer}`}>
        {icon && <div className="icon-container">{icon}</div>}
        <Input
          className="input-item"
          type={show ? "text" : type || "password"}
          value={value}
          {...props}
        />

        {show ? (
          <EyeOff
            style={{
              position: "absolute",
              right: "10px",
              top: 6,
              cursor: "pointer",
              width: "18px",
            }}
            onClick={togglePasswordVisibility}
          ></EyeOff>
        ) : (
          <Eye
            style={{
              position: "absolute",
              right: "10px",
              top: 6,
              cursor: "pointer",
              width: "18px",
            }}
            onClick={togglePasswordVisibility}
          ></Eye>
        )}
      </div>
      {error && <div className="text-red-500 text-sm -mt-2 mb-2">{error}</div>}
    </>
  );
};

export default InputPassword;
