import { Alert, AlertDescription } from "@/components/ui/alert";
import React, { useState, useEffect } from "react";

const CarruselInfo = ({ paragraphs, interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % paragraphs.length);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex, interval, paragraphs.length]);

  const renderSlides = () => {
    return paragraphs.map((paragraph, index) => (
      // <p key={index} className={`slide ${index === currentIndex ? "active" : ""}`}>
      //   {paragraph}
      // </p>

      <AlertDescription
        key={index}
        className={`${index === currentIndex ? "" : "hidden"}`}
      >
        {paragraph}
      </AlertDescription>
    ));
  };

  const renderDots = () => {
    return paragraphs.map((_, index) => (
      <span
        key={index}
        className={`dot ${index === currentIndex ? "active" : ""}`}
        onClick={() => goToSlide(index)}
      ></span>
    ));
  };

  return (
    <Alert className="carousel-container max-sm:min-h-[70px] max-sm:w-full !bg-white">
      <div className="carousel">{renderSlides()}</div>
      <div className="dots hidden lg:flex">{renderDots()}</div>
    </Alert>
  );
};

export default CarruselInfo;
