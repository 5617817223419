import { HelpCircle } from "lucide-react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";

const InputComponent = ({
  label,
  withTooltip,
  tooltipText,
  icon,
  withSpecs,
  classNameContainer = "",
  className = "",
  error = "",
  ...props
}) => {
  return (
    <>
      {label && (
        <Label>
          {label}
          {withTooltip && (
            <HelpCircle
              data-tooltip-id="my-tooltip"
              data-tooltip-content={tooltipText}
              className="help-icon2"
              fill="#1A222F"
              color="white"
            />
          )}
        </Label>
      )}
      <div className={`bg-white`}>
        {icon && <div className="icon-container">{icon}</div>}
        <Input
          className={`${className} ${classNameContainer}`}
          {...props}
          autoComplete="off"
        />
      </div>
      {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
    </>
  );
};

export default InputComponent;
