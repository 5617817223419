import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from "@/components/ui/sidebar";
import { FileClock, FileEdit, Home } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { NavUser } from "../nav-user";

export function AppSidebar() {
  const navigate = useNavigate();

  const items = [
    {
      title: "Inicio",
      onClick: () => {
        navigate("/dashboard");
      },
      isActive: window.location.pathname === "/dashboard",
      icon: Home,
    },
    {
      title: "Carga Documental",
      onClick: () => {
        navigate("/carga-documental");
      },
      isActive: window.location.pathname === "/carga-documental",
      icon: FileEdit,
    },
    {
      title: "Historial de Solicitudes",
      onClick: () => {
        navigate("/historial-solicitudes");
      },
      isActive: window.location.pathname === "/historial-solicitudes",
      icon: FileClock,
    },
  ];

  return (
    <Sidebar>
      <SidebarHeader>
        <img
          className="logoMenu"
          src="./images/finerik-logo.png"
          alt="logo"
        ></img>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {items.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton isActive={item.isActive} asChild>
                    <div className="cursor-pointer" onClick={item.onClick}>
                      <item.icon />
                      <span>{item.title}</span>
                    </div>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <NavUser
          user={{
            name: localStorage.getItem("name"),
            email: localStorage.getItem("email"),
          }}
        />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
