import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import React from "react";
import { FilePlus } from "lucide-react";
import { Button } from "../ui/button";

const CustomTable = (props) => {
  const { headers, data } = props;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead></TableHead>
          {headers.map((x, i) => (
            <TableHead key={i} style={{ width: x.headerSize }}>
              {x.headerName}
            </TableHead>
          ))}
          <TableHead>Acciones</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data?.map((row, index) => (
          <TableRow key={index + 1}>
            <TableCell>
              <FilePlus />
            </TableCell>
            {headers.map((header, idx) => (
              <TableCell key={idx + 1}>
                {header.field === "createdAt"
                  ? formatDate(row[header.field])
                  : row[header.field]}
              </TableCell>
            ))}
            {row.acciones && (
              <TableCell className="flex gap-x-2">
                {row.acciones.map((action, idx) => (
                  <>
                    {action && (
                      <Button key={idx + 1} onClick={action.accion}>
                        {action.icon}
                      </Button>
                    )}
                  </>
                ))}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CustomTable;
