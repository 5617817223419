import Input from "@/components/forms/input";
import Select from "@/components/forms/select";
import Textarea from "@/components/forms/textarea";
import { Button } from "@/components/ui/button";
import { DialogFooter, DialogTrigger } from "@/components/ui/dialog";
import { useState } from "react";
import CustomLoader from "../../common/CustomLoader";
import Request from "@/utils/fetchUtils";
import { useForm } from "@/utils/useForm";
import { toast } from "sonner";

const NuevaSolicitud = (props) => {
  const { setModalNuevaSolicitud } = props;
  const [loading, setLoading] = useState(false);

  const [userData, newDataChange, ,] = useForm({
    Tipo_de_servicio: "",
    tipo_de_industria: "",
    Descripcion_del_equipo: "",
    Valor_del_equipo: "",
    Plazo_de_interes: "",
    Comprar_activo: "",
  });

  const createNewDeal = async () => {
    setLoading(true);
    const tokenJwt = localStorage.getItem("jwt");
    if (
      Tipo_de_servicio !== "" ||
      tipo_de_industria !== "" ||
      Descripcion_del_equipo !== "" ||
      Valor_del_equipo !== "" ||
      Plazo_de_interes !== "" ||
      Comprar_activo !== ""
    ) {
      const res = await Request(
        "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/deal",
        {
          deal: {
            dedupId: Math.random() * (100 - 1) + 1,
            Tipo_de_servicio: Tipo_de_servicio,
            tipo_de_industria: tipo_de_industria,
            Descripcion_del_equipo: Descripcion_del_equipo,
            Valor_del_equipo: Valor_del_equipo.replace(/[^0-9.]/g, ""),
            Plazo_de_interes: Plazo_de_interes,
            Comprar_activo: Comprar_activo,
            Deal_Name: localStorage.getItem("name"),
            trigger: ["approval", "workflow", "blueprint"],
          },
        },
        "POST",
        false,
        false,
        {
          Authorization: `Bearer ${tokenJwt}`,
        }
      );
      if (res.status) {
        setLoading(false);
        toast.success("¡Ha creado una nueva solicitud!");
        setModalNuevaSolicitud(false);
      }
    } else {
      setLoading(false);
      toast.error("¡Tienes algunos campos vacíos!");
    }
  };

  const {
    Tipo_de_servicio,
    tipo_de_industria,
    Valor_del_equipo,
    Descripcion_del_equipo,
    Plazo_de_interes,
    Comprar_activo,
  } = userData;

  return (
    <>
      <CustomLoader isLoading={loading} />
      <div className="my-2 grid grid-cols-12 gap-x-3 gap-y-3">
        <div className="col-span-12 lg:col-span-6">
          <Select
            label="Tipo de servicio"
            placeholder="Selecciona una opción"
            name="Tipo_de_servicio"
            onChange={newDataChange}
            value={Tipo_de_servicio}
            withTooltip
            tooltipText={
              "Leaseback: Compramos tus activos para capitalizar tu empresa. Los activos seguirán siendo parte de tu operación durante todo el plazo. \n \nArrendamiento: Consigue el activo nuevo que necesitas para hacer crecer tu empresa sin descapitalizarte. Además, cambia el activo por uno nuevo al final del plazo."
            }
            options={[
              {
                label: "Arrendamiento", //Leasing
                value: "Leasing",
              },
              {
                label: "Sales & Leaseback",
                value: "Leaseback",
              },
            ]}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Select
            label="Tipo de activo"
            placeholder="Selecciona una opción"
            name="tipo_de_industria"
            onChange={newDataChange}
            value={tipo_de_industria}
            withTooltip={true}
            tooltipText={
              "Elige la categoría del activo que necesitas para entender mejor la solicitud."
            }
            options={[
              {
                label: "Cómputo",
                value: "Cómputo",
              },
              {
                label: "Movilidad",
                value: "Movilidad",
              },
              {
                label: "Maquinaria",
                value: "Maquinaria",
              },
              {
                label: "Motocicletas",
                value: "Motocicletas",
              },
              {
                label: "Salud",
                value: "Salud",
              },
              {
                label: "Energía",
                value: "Energía",
              },
              {
                label: "Carritos de golf",
                value: "Carritos de golf",
              },
            ]}
          ></Select>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Select
            label="¿Qué te gustaría hacer al final del plazo?"
            placeholder="Selecciona una opción"
            name="Comprar_activo"
            onChange={newDataChange}
            value={Comprar_activo}
            withTooltip
            tooltipText="No te preocupes, esta información no te compromete a comprar o remplazar el activo, es solo para fines informativos que puede darte información valiosa para tus cálculos."
            options={[
              {
                label: "Comprar",
                value: "Comprar",
              },
              {
                label: "Reemplazar",
                value: "Reemplazar",
              },
              {
                label: "No estoy seguro",
                value: "No estoy seguro",
              },
            ]}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Select
            label="¿A cuántos plazos te interesa?"
            placeholder="Selecciona una opción"
            name="Plazo_de_interes"
            onChange={newDataChange}
            value={Plazo_de_interes}
            options={[
              {
                label: "12 meses",
                value: "12 meses",
              },
              {
                label: "24 meses",
                value: "24 meses",
              },
              {
                label: "36 meses",
                value: "36 meses",
              },
              {
                label: "48 meses",
                value: "48 meses",
              },
              {
                label: "60 meses",
                value: "60 meses",
              },
            ]}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Input
            label="Valor del equipo ($)"
            placeholder="Ingresa la cantidad del valor del equipo"
            name="Valor_del_equipo"
            value={Valor_del_equipo}
            onChange={newDataChange}
          />
        </div>
        <div className="col-span-12 lg:col-span-12">
          <Textarea
            label="Descripción del equipo"
            placeholder="Describe el equipo que te interesa"
            name="Descripcion_del_equipo"
            value={Descripcion_del_equipo}
            onChange={newDataChange}
          />
        </div>
      </div>
      <DialogFooter>
        <DialogTrigger asChild>
          <Button variant="outline">Cerrar</Button>
        </DialogTrigger>
        <Button
          onClick={() => {
            createNewDeal();
          }}
        >
          Crear nueva solicitud
        </Button>
      </DialogFooter>
    </>
  );
};

export default NuevaSolicitud;
