import { Textarea } from "../ui/textarea";

const { Label } = require("../ui/label");

const TextareaComponent = ({ label, placeholder, name, value, onChange }) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <Textarea
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        className="resize-none"
      />
    </>
  );
};

export default TextareaComponent;
