import { Label } from "@/components/ui/label";
import { Progress } from "@/components/ui/progress";
import React from "react";

const ProgressDocBar = (props) => {
  const { catName, percent, arrayLength } = props;
  const he = require("he");

  const getArrayPercent = () => {
    if (arrayLength === 0) {
      return 0;
    }
    return (percent / arrayLength) * 100;
  };
  return (
    <div className="my-2">
      <Label className="text-md">{he.decode(catName)}</Label>
      <Progress value={getArrayPercent()} />
    </div>
  );
};

export default ProgressDocBar;
