import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Label } from "../ui/label";
import { HelpCircle } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

const SelectComponent = ({
  options,
  withTooltip,
  tooltipText,
  placeholder = "",
  label,
  error,
  onChange,
  name,
  value,
  ...props
}) => {
  return (
    <>
      {label && (
        <div className={`${withTooltip ? "flex items-center mb-1" : ""}`}>
          <Label>{label} </Label>
          {withTooltip && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <HelpCircle className="help-icon2" size={16} />
                </TooltipTrigger>
                <TooltipContent className="max-w-[300px] text-sm">
                  <Label className="text-sm">{tooltipText}</Label>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      )}
      <Select
        defaultValue={value}
        onValueChange={(e) => onChange({ target: { name, value: e } })}
        {...props}
      >
        <SelectTrigger>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {options.map((option) => (
              <SelectItem value={option.value}>{option.label}</SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
    </>
  );
};

export default SelectComponent;
