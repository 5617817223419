/* eslint-disable no-unused-vars */
import { Input } from "@/components";
import Modal from "@/components/modal";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import Swal from "sweetalert2";
import CustomLoader from "../../common/CustomLoader";
import CustomMoffinModalTitle from "../../common/CustomMoffinModalTitle";
import CustomProgressBar from "../../common/CustomProgressBar";
import LogoMuffin from "../../common/LogoMoffin";
import SpellingCorrection from "../../common/SpellingCorrection";
import { fetchDataFoldersZoho } from "../../redux/actions/generalActions";
import Request from "../../utils/fetchUtils";
import { useForm } from "../../utils/useForm";
import { states } from "@/lib/data";
import SelectComponent from "@/components/forms/select";

const FlujoMoffin = (props) => {
  const { folderData, sameAvalRL } = props;
  const [modalUploadDocs, setModalUploadDocs] = useState(false);
  const [modalUploadDocs2, setModalUploadDocs2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step1Selected, setStep1Selected] = useState(true);
  const [step2Selected, setStep2Selected] = useState(false);
  const [step3Selected, setStep3Selected] = useState(false);
  const dispatch = useDispatch();

  const [isRLsameOS, setisRLsameOS] = useState(false);

  const [randomNumber, setRandomNumber] = useState(generateRandomNumber());
  const [userInput, setUserInput] = useState("");
  const [isValid, setIsValid] = useState(true);

  function handleChange(event) {
    setUserInput(event.target.value);
  }

  const [estatusData, setEstatusData] = useState([]);

  function generateRandomNumber() {
    return Math.floor(1000 + Math.random() * 9000);
  }

  const [userData, newDataChange, ,] = useForm({
    Primer_nombre: "",
    Segundo_nombre: "",
    Primer_apellido: "",
    Segundo_apellido: "",
    Razon_social: "",
    CURP: "",
    Calle: "",
    Codigo_postal: "",
    Estado: "",
    Numero_exterior: "",
    Numero_interior: "",
    Colonia: "",
    Municipio: "",
    Fecha_de_nacimiento: "",
    Email_Aval: "",
    Email_Representante_Legal: "",
    Representante_legal_mismo_que_obligado_solidario: isRLsameOS ? "Si" : "No",
  });

  useEffect(() => {
    setEstatusData(folderData);
  }, [folderData]);

  function formatearNombre(nombre) {
    const palabras = nombre.split("_");
    const nombreFormateado = palabras
      .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))
      .join(" ");

    return nombreFormateado;
  }

  const changeToStep1 = () => {
    setStep1Selected(true);
    setStep2Selected(false);
    setStep3Selected(false);
  };

  const changeToStep2 = () => {
    setStep1Selected(false);
    setStep2Selected(true);
    setStep3Selected(false);
  };

  const changeToStep3 = () => {
    setStep1Selected(false);
    setStep2Selected(false);
    setStep3Selected(true);
  };

  const formatDateToBackend = (date) => {
    const [day, month, year] = date.split("/");
    return `${year}/${month}/${day}`;
  };

  const autorizacionMoffin = async () => {
    const tokenJwt = localStorage.getItem("jwt");
    if (parseInt(userInput) === randomNumber) {
      const res = await Request(
        "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/contact",
        {
          contact: {
            CURP: CURP.toUpperCase(),
            Raz_n_social: Razon_social,
            Fecha_de_nacimiento: Fecha_de_nacimiento
              ? formatDateToBackend(Fecha_de_nacimiento)
              : "",
            Primer_nombre: Primer_nombre,
            Segundo_nombre: Segundo_nombre,
            Primer_apellido: Primer_apellido,
            Segundo_apellido: Segundo_apellido,
            Codigo_postal: Codigo_postal,
            Calle: Calle,
            Municipio: Municipio,
            Estado: Estado,
            Colonia: Colonia,
            Numero_exterior: Numero_exterior,
            Numero_interior: Numero_interior,
            Pa_s: "MX", //nuevo
          },
        },
        "PATCH",
        false,
        false,
        {
          Authorization: `Bearer ${tokenJwt}`,
        }
      );
      let timerInterval;
      if (res.ok) {
        Swal.fire({
          icon: "success",
          title: "¡Éxito!",
          text: "Revise el estatus en su dashboard para más seguimiento",
          html: "Verá reflejado sus cambios en <b></b> segundos.",
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            timerInterval = setInterval(() => {
              timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
            }, 10);
          },
        }).then(
          () => setModalUploadDocs(false),
          setModalUploadDocs2(false),
          dispatch(fetchDataFoldersZoho(setLoading))
        );
      } else {
        toast.error("¡Oh no algo salió mal!");
      }
    } else {
      toast.error("Error: el número ingresado no coincide. Inténtalo de nuevo");
    }
  };

  const autorizacionCorreoRLOS = async () => {
    const tokenJwt = localStorage.getItem("jwt");
    const res = await Request(
      "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/contact",
      {
        contact: {
          Email_Aval: Email_Aval, //nuevo
          Email_Representante_Legal: Email_Representante_Legal, //nuevo
        },
      },
      "PATCH",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    let timerInterval;
    if (res.ok) {
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "Revise el estatus en su dashboard para más seguimiento",
        html: "Verá reflejado sus cambios en <b></b> segundos.",
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
          }, 10);
        },
      }).then(
        () => setModalUploadDocs(false),
        setModalUploadDocs2(false),
        dispatch(fetchDataFoldersZoho(setLoading))
      );
    } else {
      toast.error("¡Oh no algo salió mal!");
    }
  };

  const {
    Primer_nombre,
    Segundo_nombre,
    Primer_apellido,
    Segundo_apellido,
    Razon_social,
    Email_Aval,
    Fecha_de_nacimiento,
    CURP,
    Calle,
    Codigo_postal,
    Estado,
    Numero_exterior,
    Numero_interior,
    Colonia,
    Municipio,
    Email_Representante_Legal,
  } = userData;

  return (
    <>
      <CustomLoader isLoading={loading} />
      <Card
        className="cursor-pointer"
        onClick={
          sameAvalRL &&
          estatusData?.Carpeta_superior ===
            "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;"
            ? null
            : () =>
                estatusData?.Carpeta_superior ===
                  "Documentaci&oacute;n Express" &&
                estatusData?.Estatus === "Pendiente"
                  ? setModalUploadDocs(true)
                  : estatusData?.Carpeta_superior !==
                    "Documentaci&oacute;n Express"
                  ? setModalUploadDocs2(true)
                  : {}
        }
      >
        <div className="flex items-center justify-between px-4 gap-x-3 py-2">
          <div className="flex items-center gap-x-3">
            <div>
              {estatusData?.Estatus === "Pendiente" && (
                <img
                  className="iconItem h-10"
                  src="./images/nuevoIcon.svg"
                  alt=""
                ></img>
              )}
              {estatusData?.Estatus === "En revisión" && (
                <img
                  className="iconItem h-10"
                  src="./images/esperaIcon.svg"
                  alt=""
                ></img>
              )}
              {estatusData?.Estatus === "Aprobado" && (
                <img
                  className="iconItem h-10"
                  src="./images/aceptadoIcon.svg"
                  alt=""
                ></img>
              )}
              {estatusData?.Estatus === "Rechazado" && (
                <img
                  className="iconItem h-10"
                  src="./images/rechazadoIcon.svg"
                  alt=""
                ></img>
              )}
            </div>
            {/* <LogoMuffin></LogoMuffin> */}
            <Label className="text-sm font-semibold">
              <SpellingCorrection
                texto={formatearNombre(folderData.Name)}
              ></SpellingCorrection>
            </Label>
          </div>
          {sameAvalRL &&
          estatusData?.Carpeta_superior ===
            "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" ? (
            <Label>Autorización desde el Representante Legal</Label>
          ) : (
            <div>
              {estatusData?.Estatus === "Pendiente" && (
                <Label>
                  Haga clic aquí para autorizar con Moffin la consulta a su buró
                  de crédito...
                </Label>
              )}
            </div>
          )}
        </div>
      </Card>
      <Modal open={modalUploadDocs} setOpen={setModalUploadDocs}>
        <Label className="text-center text-xl font-semibold">
          Solicitud de revisión a buró de crédito
        </Label>
        <span className="text-center my-2 mb-3">
          Confirma que los datos del solicitante sean correctos
        </span>
        <div
          className="container-progressBar"
          style={{ width: "100%", marginTop: 0 }}
        >
          <CustomProgressBar
            porcent={
              step1Selected ? 0 : step2Selected ? 25 : step3Selected ? 50 : 75
            }
          ></CustomProgressBar>
        </div>
        <div
          className="modal-container"
          style={{
            width: "100%",
            justifyContent: "space-between",
            height: "35vw",
          }}
        >
          <div className="steps-container">
            <div
              className="sept1-container"
              style={
                step2Selected || step3Selected
                  ? { display: "none" }
                  : { width: "100%" }
              }
            >
              {/* <CustomMoffinModalTitle
                title={"Solicitud de revisión a buró de crédito"}
                subtitle={
                  "Confirma que los datos del solicitante sean correctos"
                }
                withHelp={true}
                helpText={
                  "Finerik utiliza la tecnología de Moffin para automatizar las consultas de buró de crédito, tus datos están completamente seguros."
                }
              ></CustomMoffinModalTitle> */}
              <span className="text-xs text-gray-400 text-center mb-1">
                Finerik utiliza la tecnología de Moffin para automatizar las
                consultas de buró de crédito, tus datos están completamente
                seguros.
              </span>
              {localStorage.getItem("layout") === "Persona Fisica" && (
                <div className="form-twoColumns-container">
                  <div className="input-twoColumns-container">
                    <Input
                      styles={{ color: "black" }}
                      label="Primer nombre"
                      placeholder="Primer nombre"
                      name="Primer_nombre"
                      value={Primer_nombre}
                      onChange={newDataChange}
                    ></Input>
                  </div>
                  <div className="input-twoColumns-container">
                    <Input
                      styles={{ color: "black" }}
                      label="Segundo nombre"
                      placeholder="Segundo nombre"
                      name="Segundo_nombre"
                      value={Segundo_nombre}
                      onChange={newDataChange}
                    ></Input>
                  </div>
                  <div className="input-twoColumns-container">
                    <Input
                      styles={{ color: "black" }}
                      label="Primer apellido"
                      placeholder="Primer apellido"
                      name="Primer_apellido"
                      value={Primer_apellido}
                      onChange={newDataChange}
                    ></Input>
                  </div>
                  <div className="input-twoColumns-container">
                    <Input
                      styles={{ color: "black" }}
                      label="Segundo apellido"
                      placeholder="Segundo apellido"
                      name="Segundo_apellido"
                      value={Segundo_apellido}
                      onChange={newDataChange}
                    ></Input>
                  </div>
                  <div className="input-twoColumns-container">
                    <Input
                      styles={{ color: "black" }}
                      label="Fecha de Nacimiento (DD/MM/YYYY)"
                      placeholder="Fecha de nacimiento (DD/MM/YYYY)"
                      name="Fecha_de_nacimiento"
                      value={Fecha_de_nacimiento}
                      onChange={newDataChange}
                    ></Input>
                  </div>
                </div>
              )}
              {localStorage.getItem("layout") === "Persona Moral" && (
                <div className="form-twoColumns-container">
                  <div className="input-twoColumns-container">
                    <Input
                      styles={{ color: "black" }}
                      label="Razón social o nombre"
                      placeholder="Razon social"
                      name="Razon_social"
                      value={Razon_social}
                      onChange={newDataChange}
                    ></Input>
                  </div>
                </div>
              )}
            </div>
            <div
              className="sept2-container"
              style={
                step1Selected || step3Selected
                  ? { display: "none" }
                  : { width: "100%" }
              }
            >
              {/* <CustomMoffinModalTitle
                title={"Solicitud de revisión a buró de crédito"}
                subtitle={
                  "Confirma que los datos del solicitante sean correctos"
                }
                withHelp={true}
                helpText={
                  "Finerik utiliza la tecnología de Moffin para automatizar las consultas de buró de crédito, tus datos están completamente seguros."
                }
              ></CustomMoffinModalTitle> */}
              <span className="text-xs text-gray-400 text-center mb-1">
                Finerik utiliza la tecnología de Moffin para automatizar las
                consultas de buró de crédito, tus datos están completamente
                seguros.
              </span>
              {localStorage.getItem("layout") === "Persona Fisica" && (
                <div style={{ height: "22vw", overflow: "auto" }}>
                  <Label className="font-semibold text-md my-1">
                    Datos Generales
                  </Label>
                  <div className="form-twoColumns-container mb-3">
                    <div className="input-twoColumns-container">
                      <Input
                        styles={{ color: "black" }}
                        label="CURP"
                        placeholder="CURP"
                        name="CURP"
                        value={CURP}
                        onChange={newDataChange}
                      ></Input>
                    </div>
                  </div>
                  <Label className="font-semibold text-md my-1 mt-4">
                    Domicilio
                  </Label>
                  <div className="form-twoColumns-container">
                    <div className="input-twoColumns-container">
                      <Input
                        styles={{ color: "black" }}
                        label="Calle"
                        placeholder="Calle"
                        name="Calle"
                        value={Calle}
                        onChange={newDataChange}
                      ></Input>
                    </div>
                    <div
                      className="input-twoColumns-container"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "48%" }}>
                        <Input
                          styles={{ color: "black" }}
                          label="Número exterior"
                          placeholder="Número exterior"
                          name="Numero_exterior"
                          value={Numero_exterior}
                          onChange={newDataChange}
                        ></Input>
                      </div>
                      <div style={{ width: "48%" }}>
                        <Input
                          styles={{ color: "black" }}
                          label="Número interior"
                          placeholder="Número interior"
                          name="Numero_interior"
                          value={Numero_interior}
                          onChange={newDataChange}
                        ></Input>
                      </div>
                    </div>
                    <div className="input-twoColumns-container">
                      <Input
                        styles={{ color: "black" }}
                        label="Código Postal"
                        placeholder="CP"
                        name="Codigo_postal"
                        value={Codigo_postal}
                        onChange={newDataChange}
                      ></Input>
                    </div>
                    <div className="input-twoColumns-container">
                      <Input
                        styles={{ color: "black" }}
                        label="Colonia"
                        placeholder="Colonia"
                        name="Colonia"
                        value={Colonia}
                        onChange={newDataChange}
                      ></Input>
                    </div>
                    <div className="input-twoColumns-container">
                      <SelectComponent
                        label={"Estado"}
                        placeholder="Estado"
                        value={Estado}
                        onChange={newDataChange}
                        options={states}
                      />
                    </div>
                    <div className="input-twoColumns-container">
                      <Input
                        styles={{ color: "black" }}
                        label="Delegación/Municipio"
                        placeholder="Delegación/Municipio"
                        name="Municipio"
                        value={Municipio}
                        onChange={newDataChange}
                      ></Input>
                    </div>
                  </div>
                </div>
              )}
              {localStorage.getItem("layout") === "Persona Moral" && (
                <div style={{ height: "22vw", overflow: "auto" }}>
                  <div className="form-twoColumns-container">
                    <div className="input-twoColumns-container">
                      <Input
                        styles={{ color: "black" }}
                        label="Calle"
                        placeholder="Calle"
                        name="Calle"
                        value={Calle}
                        onChange={newDataChange}
                      ></Input>
                    </div>
                    <div
                      className="input-twoColumns-container"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "48%" }}>
                        <Input
                          styles={{ color: "black" }}
                          label="Número exterior"
                          placeholder="Número exterior"
                          name="Numero_exterior"
                          value={Numero_exterior}
                          onChange={newDataChange}
                        ></Input>
                      </div>
                      <div style={{ width: "48%" }}>
                        <Input
                          styles={{ color: "black" }}
                          label="Número interior"
                          placeholder="Número interior"
                          name="Numero_interior"
                          value={Numero_interior}
                          onChange={newDataChange}
                        ></Input>
                      </div>
                    </div>
                    <div className="input-twoColumns-container">
                      <Input
                        styles={{ color: "black" }}
                        label="Código Postal"
                        placeholder="CP"
                        name="Codigo_postal"
                        value={Codigo_postal}
                        onChange={newDataChange}
                      ></Input>
                    </div>
                    <div className="input-twoColumns-container">
                      <Input
                        styles={{ color: "black" }}
                        label="Colonia"
                        placeholder="Colonia"
                        name="Colonia"
                        value={Colonia}
                        onChange={newDataChange}
                      ></Input>
                    </div>
                    <div className="input-twoColumns-container">
                      <SelectComponent
                        label={"Estado"}
                        placeholder="Estado"
                        value={Estado}
                        onChange={newDataChange}
                        options={states}
                      />
                    </div>
                    <div className="input-twoColumns-container">
                      <Input
                        styles={{ color: "black" }}
                        label="Delegación/Municipio"
                        placeholder="Delegación/Municipio"
                        name="Municipio"
                        value={Municipio}
                        onChange={newDataChange}
                      ></Input>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="sept3-container !bg-white"
              style={
                step1Selected || step2Selected
                  ? { display: "none" }
                  : { width: "100%" }
              }
            >
              <Label className="text-center text-xl font-semibold">
                Autorización de consulta de historial crediticio
              </Label>
              <div
                style={{
                  height: "17vw",
                  overflow: "auto",
                  padding: "1vw",
                  borderRadius: "1vw",
                }}
                className="border border-gray-200"
              >
                <Label className="text-sm">
                  Por este conducto autorizo expresamente a Moffin Software
                  S.A.P.I. de C.V., para que por conducto de sus funcionarios
                  facultados lleve a cabo Investigaciones, sobre mi
                  comportamiento crediticio o el de la Empresa que represento en
                  Trans Union de México, S. A. SIC y/o Dun & Bradstreet, S.A.
                  SIC.
                </Label>
                <Label className="text-sm">
                  Así mismo, declaro que conozco la naturaleza y alcance de la
                  información que se solicitará, del uso que Moffin Software
                  S.A.P.I. de C.V., hará de tal información y de que ésta podrá
                  realizar consultas periódicas sobre mi historial o el de la
                  empresa que represento, consintiendo que esta autorización se
                  encuentre vigente por un período de 3 años contados a partir
                  de su expedición y en todo caso durante el tiempo que se
                  mantenga la relación jurídica
                </Label>
                <Label className="text-sm">
                  En caso de que la solicitante sea una Persona Moral, declaro
                  bajo protesta de decir verdad Ser Representante Legal de la
                  empresa mencionada en esta autorización; manifestando que a la
                  fecha de firma de la presente autorización los poderes no me
                  han sido revocados, limitados, ni modificados en forma alguna.
                </Label>
              </div>
              <div className="form-twoColumns-container">
                <div className="input-twoColumns-container">
                  <div>
                    Código de registro es:{" "}
                    <span style={{ fontWeight: 700, color: "#9be69f" }}>
                      {randomNumber}
                    </span>
                  </div>
                  <span style={{ fontStyle: "italic", fontSize: "0.8vw" }}>
                    Ingrésalo en el siguiente campo de texto para continuar.
                  </span>
                </div>
                <div className="input-twoColumns-container">
                  <Input
                    styles={{ color: "black" }}
                    label="Código"
                    placeholder="Código"
                    name="codigo"
                    value={userInput}
                    onChange={handleChange}
                  ></Input>
                  {!isValid && (
                    <p style={{ color: "red" }}>
                      Error: el número ingresado no coincide.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="support-container"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
              marginTop: "2vw",
            }}
          >
            <Button
              variant="outline"
              onClick={() =>
                step1Selected
                  ? setModalUploadDocs(false)
                  : step2Selected
                  ? changeToStep1()
                  : step3Selected
                  ? changeToStep2()
                  : {}
              }
            >
              {step1Selected || step3Selected ? "Cancelar" : "Regresar"}
            </Button>
            <Button
              onClick={() =>
                step1Selected
                  ? changeToStep2()
                  : step2Selected
                  ? changeToStep3()
                  : step3Selected
                  ? autorizacionMoffin()
                  : {}
              }
            >
              {step3Selected ? "Autorizar" : "Continuar"}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={modalUploadDocs2} setOpen={setModalUploadDocs2}>
        <Label className="text-center text-xl font-semibold">
          Solicitud de revisión a buró de crédito
        </Label>
        <div
          className="modal-container"
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={
              step2Selected || step3Selected
                ? { display: "none" }
                : { width: "100%" }
            }
          >
            <Label className="text-sm text-center text-gray-400">
              A continuación ingrese un correo donde podamos contactar al Aval o
              Representante Legal para poder pedirle sus datos.
            </Label>
            <div>
              <div className="min-h-40 py-2">
                {folderData.Carpeta_superior ===
                  "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" && (
                  <Input
                    styles={{ color: "black" }}
                    label="Correo electrónico"
                    placeholder="Correo electrónico"
                    name="Email_Aval"
                    value={Email_Aval}
                    onChange={newDataChange}
                  />
                )}
                {folderData.Carpeta_superior !==
                  "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" && (
                  <Input
                    styles={{ color: "black" }}
                    label="Correo electrónico"
                    placeholder="Correo electrónico"
                    name="Email_Representante_Legal"
                    value={Email_Representante_Legal}
                    onChange={newDataChange}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-3 justify-end mt-3">
            <Button
              variant="outline"
              onClick={() =>
                step1Selected
                  ? setModalUploadDocs2(false)
                  : step2Selected
                  ? changeToStep1()
                  : step3Selected
                  ? changeToStep2()
                  : {}
              }
            >
              {step1Selected || step3Selected ? "Cancelar" : "Regresar"}
            </Button>
            <Button
              onClick={() =>
                estatusData?.Carpeta_superior ===
                  "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" ||
                estatusData?.Carpeta_superior ===
                  "Documentos del representante legal &#x28;RL&#x29;"
                  ? autorizacionCorreoRLOS()
                  : autorizacionMoffin()
              }
            >
              {step3Selected ? "Autorizar" : "Continuar"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

FlujoMoffin.propTypes = {};

export default FlujoMoffin;
