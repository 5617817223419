import React from "react";
import CustomProgressBar from "@/common/CustomProgressBar";
import { useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import SuccessIcon from "@/assets/icons/aceptado.png";

const ViewCongrats = () => {
  const navigate = useNavigate();

  return (
    <Card>
      <div className="p-4 flex flex-col justify-center items-center">
        <img
          className="h-32 my-2"
          src={SuccessIcon}
          alt="congradulation icon"
        ></img>
        <CustomProgressBar
          porcent={100}
          finishProgress={true}
        ></CustomProgressBar>
        <Label className="text-center font-bold text-4xl my-2 mt-4">
          ¡Felicidades!
          <br /> Has sido pre-aprobado
        </Label>
        <Label className="text-center">
          Hemos preparado una pre-cotización para tí, pulsa el siguiente botón
          para descargarla
        </Label>
        <div className="flex my-8 gap-x-4 items-center justify-center gap-y-4">
          <Button
            variant="secondary"
            onClick={() => {
              navigate("/preliminary-quote");
            }}
          >
            Descargar
          </Button>
          <Button
            onClick={() => {
              navigate("/carga-documental");
            }}
          >
            Continuar
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ViewCongrats;
