import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { HelpCircle } from "lucide-react";
import { Label } from "../ui/label";

const InputPhoneCountry = ({
  value,
  onChange,
  withTooltip,
  label,
  tooltipText,
  classNameContainer = "",
  error = "",
  ...props
}) => {
  return (
    <>
      {label && (
        <Label>
          {label}
          {withTooltip && (
            <HelpCircle
              data-tooltip-id="my-tooltip"
              data-tooltip-content={tooltipText}
              className="help-icon2"
              fill="#1A222F"
              color="white"
            />
          )}
        </Label>
      )}
      <PhoneInput
        defaultCountry="mx"
        value={value}
        onChange={onChange}
        className={`flex h-9 w-full rounded-md border border-input bg-transparent text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm ${classNameContainer}`}
        countrySelectorStyleProps={{
          className: "!max-h-full",
          buttonClassName:
            "flex w-full rounded-md !border-none border-input bg-transparent md:text-sm",
          flagClassName: "!h-full",
          buttonStyle: {
            height: "99.9%",
          },
          style: {
            width: 80,
            border: "none",
            backgroundColor: "transparent",
          },
          dropdownStyleProps: {
            className: "!max-h-[150px]",
          },
        }}
      />
      {error && <div className="text-red-500 text-sm -mt-2 mb-2">{error}</div>}
    </>
  );
};

export default InputPhoneCountry;
