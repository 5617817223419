import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomLoader from "@/common/CustomLoader";
import SpellingCorrection from "@/common/SpellingCorrection";
import { encryptData, isValidRFC } from "@/utils";
import { axiosInstance } from "@/utils/axios-instance";
import Input from "../forms/input";
import InputPassword from "../forms/input-password";
import { Card } from "../ui/card";
import { toast } from "sonner";
import { Label } from "../ui/label";
import Modal from "../modal";
import { Button } from "../ui/button";

const EkatenaRequest = ({ folderData }) => {
  function formatearNombre(nombre) {
    const palabras = nombre.split("_");

    const nombreFormateado = palabras
      .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))
      .join(" ");

    return nombreFormateado;
  }
  const { userData } = useSelector((state) => state.general);

  const [createReport, setCreateReport] = useState(null);
  const [checked, setChecked] = useState(
    localStorage.getItem("avalMismoRL") === "Si" ? true : false
  );
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isValidRfc, setIsValidRfc] = useState(false);
  const [error, setError] = useState({ text: "", type: null });
  const [values, setValues] = useState({
    ciec: "",
    rfc: "",
  });

  const loadDataMe = async () => {
    setLoading(true);
    axiosInstance
      .get("/user/me")
      .then((res) => {
        if (res.data) {
          setValues({ ...values, rfc: res.data?.RFC });
          setLoading(false);
          let status = "ERROR";

          if (!res.data?.reportEkatena?.id) status = "INITIAL";

          if (res.data?.reportEkatena?.pdfReportUrl) status = "SUCCESS";
          if (
            !res.data?.reportEkatena?.pdfReportUrl &&
            res.data?.reportEkatena?.id
          )
            status = "PENDING";

          if (res.data?.reportEkatena?.ciedStatus === "INVALID")
            status = "INVALID";
          console.log("status", status, res.data?.reportEkatena);
          setCreateReport(status);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("e", e);
      });
  };

  useEffect(() => {
    loadDataMe();
  }, []);

  const onSubmit = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    try {
      const encryptedData = encryptData({
        ciec: values.ciec,
        rfc: values.rfc,
      });

      const resp = await axiosInstance.post(
        "/admin/ekatena-report",
        encryptedData
      );
      if (resp?.data?.id) {
        setLoading(false);
        setOpenModal(false);
        toast.success("Se ha generado el reporte con éxito");
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const newDataChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <CustomLoader isLoading={loading} />
      <Card
        className="cursor-pointer"
        onClick={() =>
          (createReport === "INITIAL" || createReport === "INVALID") &&
          setOpenModal(true)
        }
      >
        <div className="flex items-center justify-between px-4 gap-x-3 py-2">
          <div className="flex items-center gap-x-3">
            {createReport === "PENDING" ? (
              <img
                className="iconItem h-10"
                src="./images/esperaIcon.svg"
                alt=""
              ></img>
            ) : createReport === "SUCCESS" ? (
              <img
                className="iconItem h-10"
                src="./images/aceptadoIcon.svg"
                alt=""
              ></img>
            ) : createReport === "INVALID" || createReport === "INITIAL" ? (
              <img
                className="iconItem h-10"
                src="./images/nuevoIcon.svg"
                alt=""
              ></img>
            ) : (
              <img
                className="iconItem h-10"
                src="./images/rechazadoIcon.svg"
                alt=""
              ></img>
            )}

            <Label className="text-sm font-semibold">
              <SpellingCorrection texto={formatearNombre(folderData?.Name)} />
            </Label>
          </div>
          <div className="!mt-0">
            {createReport === "INVALID" ? (
              <Label>CIEC invalida, comunicate con el administrador.</Label>
            ) : createReport === "PENDING" ? (
              <Label>Su reporte se está generando, por favor espere.</Label>
            ) : (
              <Label>Haga clic aquí para generar su reporte...</Label>
            )}
          </div>
        </div>
      </Card>
      <Modal open={openModal} setOpen={setOpenModal}>
        <Label className="font-semibold text-center text-xl mb-3">
          Conexion al SAT para información financiera
        </Label>
        <form className="grid grid-cols-12 gap-4" onSubmit={onSubmit}>
          <div className="col-span-12">
            <Input
              label="RFC"
              placeholder="RFC"
              name="rfc"
              value={values.rfc}
              onChange={({ target: { value, name } }) => {
                if ((value && value?.length <= 13) || !value) {
                  const isValid = isValidRFC(value, checked);
                  setIsValidRfc(isValid);

                  newDataChange({ target: { name, value } });
                }
              }}
              classNameContainer={
                (!isValidRfc && userData.RFC) || error.type === "RFC"
                  ? "!border !border-red-400"
                  : ""
              }
              className={!isValidRfc && userData.RFC ? "!text-red-400" : ""}
            />
          </div>
          <div className="col-span-12">
            <InputPassword
              placeholder="Ingresa tu CIEC"
              name="ciec"
              label="Ingresa tu CIEC"
              autoComplete="off"
              value={values.ciec}
              onChange={newDataChange}
              classNameContainer={
                error?.type === "ciec" ? "!border-[#DC3545]" : ""
              }
            />
          </div>

          <div className="col-span-12 flex items-center justify-center gap-x-3 mt-4">
            <Button variant="outline" onClick={() => setOpenModal(!openModal)}>
              Cerrar
            </Button>
            <Button onClick={onSubmit}>Guardar</Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EkatenaRequest;
