import { DialogHeader, DialogTitle } from "@/components/ui/dialog";
import Modal from "..";
import NuevaSolicitud from "@/modules/PortalCliente/NuevaSolicitud";

const NewRequest = ({ open, setOpen, onSave }) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <DialogHeader>
        <DialogTitle>
          Cuéntanos sobre el nuevo servicio que quieres explorar
        </DialogTitle>
      </DialogHeader>
      <NuevaSolicitud setModalNuevaSolicitud={onSave}></NuevaSolicitud>
    </Modal>
  );
};

export default NewRequest;
