import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Request, { BASE_URLS } from "@/utils/fetchUtils";
import { useForm } from "@/utils/useForm";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import Divider from "@/components/ui/divider";
import { Loader } from "lucide-react";
import InputOtp from "@/components/input-otp";
import { InputPassword, PasswordMinRequired } from "@/components";
import { isPasswordSecure } from "@/utils";
import { toast } from "sonner";
import LogoWhite from "@/assets/logo/LogoWhite.svg";

const ForgotSteps = () => {
  const [step, setStep] = useState(1);
  const [dataRecurrent, setDataRecurrent] = useState({
    email: "",
    code: "",
  });

  const stepView = {
    1: (
      <ForgotForm
        setStep={setStep}
        step={step}
        setDataRecurrent={setDataRecurrent}
        dataRecurrent={dataRecurrent}
      />
    ),
    2: (
      <ForgotPasswordForm
        setStep={setStep}
        step={step}
        code={dataRecurrent.code}
        email={dataRecurrent.email}
      />
    ),
  };

  return (
    <div className="flex h-screen">
      <div className="w-1/2 bg-gray-900 text-white hidden lg:flex flex-col justify-between p-8">
        <div className="text-xl font-bold">
          <img className="h-5" src={LogoWhite} alt="loginIcon"></img>
        </div>
        <div className="text-sm">
          <blockquote>
            Optimiza el flujo de efectivo de tu PYME con arrendamiento de
            activos ágil y LIBRE DE FRICCIONES.
          </blockquote>
        </div>
      </div>

      <div className="w-full lg:w-1/2 flex items-center justify-center max-sm:px-4">
        {stepView[step]}
      </div>
    </div>
  );
};

const ForgotForm = ({ setStep, step, setDataRecurrent, dataRecurrent }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inForgotProcess, setInForgotProcess] = useState(false);
  const [userData, newDataChange] = useForm({
    email: "",
  });

  const forgotPassword = async () => {
    if (!userData.email) {
      return;
    }
    setDataRecurrent({
      ...dataRecurrent,
      email: userData.email,
    });

    setLoading(true);
    const res = await Request(
      `${BASE_URLS.GENERAL}/staging/auth/forgot-password`,
      {
        email,
      },
      "POST"
    );
    if (res.status) {
      setInForgotProcess(true);
      toast.success("Se ha enviado un código a tu correo electrónico");
    } else {
      toast.error("¡Oh no, algo salió mal! Inténtalo de nuevo");
    }
    setLoading(false);
  };

  const { email } = userData;
  return (
    <Card className="lg:w-3/4 p-8">
      <img
        className="iconView"
        src="/images/finerik-logo.png"
        alt="loginIcon"
      ></img>
      <Label className="mb-4 text-lg">Recuperar tu contraseña</Label>
      <p className="text-sm mb-4">
        Ingresa tu correo electrónico y te enviaremos un código para la
        recuperación de tu contraseña
      </p>

      <Label className="mb-1">Correo electrónico</Label>
      <Input
        placeholder="name@example.com"
        type="email"
        value={email}
        name="email"
        onChange={newDataChange}
        disabled={inForgotProcess}
        className="mb-4"
      />

      {inForgotProcess && (
        <>
          <p className="text-sm mb-4">
            Te hemos enviado un código de verificación a tu correo electrónico
          </p>
          <ForgotValidationCode
            email={email}
            setLoading={setLoading}
            setStep={setStep}
            step={step}
            loading={loading}
            setDataRecurrent={setDataRecurrent}
            dataRecurrent={dataRecurrent}
          />
        </>
      )}
      {!inForgotProcess && (
        <>
          <Button
            onClick={forgotPassword}
            disabled={loading || inForgotProcess}
            className="w-full mb-4"
          >
            {loading ? (
              <Loader isLoading={loading}></Loader>
            ) : (
              <span>Recuperar contraseña</span>
            )}
          </Button>

          <Divider>O si ya tienes una cuenta</Divider>
          <Button
            onClick={() => {
              navigate("/auth/login");
            }}
            className="w-full mt-4 flex items-center justify-center space-x-2"
          >
            <span>Iniciar sesión</span>
          </Button>
        </>
      )}
    </Card>
  );
};

const ForgotValidationCode = ({
  email,
  setLoading,
  loading,
  setStep,
  step,
  setDataRecurrent,
  dataRecurrent,
}) => {
  const [codigo, setCodigo] = useState("");

  const actualizarCodigo = (nuevoCodigo) => {
    setCodigo(nuevoCodigo);
  };

  const verifyEmail = async () => {
    setLoading(true);
    setDataRecurrent({
      ...dataRecurrent,
      code: codigo,
    });
    try {
      const res = await Request(
        `${BASE_URLS.GENERAL}/staging/auth/verify-email`,
        {
          email,
          confirmationCode: codigo,
        },
        "POST"
      );
      setLoading(false);
      if (res.status) {
        setStep(step + 1);
      } else {
        if (
          res?.message ===
          "User cannot be confirmed. Current status is CONFIRMED"
        ) {
          setStep(step + 1);
          return;
        }

        toast.error("¡Oh no, algo salió mal! Inténtalo de nuevo");
      }
    } catch (e) {
      console.log(e, e.message);
    }
    setLoading(false);
  };

  return (
    <div className="my-4 flex flex-col gap-4 justify-center items-center">
      <InputOtp value={codigo} setValue={actualizarCodigo} disable={loading} />
      <Button onClick={verifyEmail} disabled={loading} className="w-full mb-4">
        {loading ? (
          <Loader isLoading={loading}></Loader>
        ) : (
          <span>Verificar código</span>
        )}
      </Button>
    </div>
  );
};

const ForgotPasswordForm = ({ code, email }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [contraseñasCoinciden, setContraseñasCoinciden] = useState(true);
  const [cumpleRequisitos, setCumpleRequisitos] = useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      setPassword(value);
    } else if (name === "passwordConfirm") {
      setConfirmPassword(value);
    }

    const isStrong = isPasswordSecure(value);
    setCumpleRequisitos(isStrong);

    setContraseñasCoinciden(value === password);
  };

  const resetPassword = async () => {
    setLoading(true);
    if (contraseñasCoinciden && cumpleRequisitos) {
      const res = await Request(
        `${BASE_URLS.GENERAL}/staging/auth/reset-password`,
        {
          email,
          password: password,
          confirmationCode: code,
        },
        "POST"
      );
      if (res.status) {
        toast("Contraseña actualizada con exito!", { type: "success" });
        navigate("/auth/login");
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Ocurrio algo inesperado, intenta de nuevo");
      }
    } else {
      setLoading(false);
      toast.error(
        "Revisa que tus contraseñas coincidan y/o que cumplan con los requisitos."
      );
    }
  };
  return (
    <Card className="lg:w-3/4 p-8">
      <img
        className="iconView"
        src="/images/finerik-logo.png"
        alt="loginIcon"
      ></img>
      <Label className="mb-4 text-lg">Recuperar tu contraseña</Label>
      <p className="text-sm mb-4">
        Ingresa tu contraseña y confírmala para recuperar tu cuenta.
      </p>
      <div className="mb-2">
        <InputPassword
          placeholder="Contraseña"
          name="password"
          type="password"
          label="Crea tu contraseña"
          value={password}
          styles={{
            border: contraseñasCoinciden ? "2px solid green" : "2px solid red",
          }}
          onChange={handleInputChange}
        />
      </div>
      <InputPassword
        placeholder="Confirmar Contraseña"
        name="passwordConfirm"
        type="password"
        label="Confirma tu contraseña"
        value={confirmPassword}
        withSpecs
        styles={{
          border: contraseñasCoinciden ? "2px solid green" : "2px solid red",
        }}
        onChange={handleInputChange}
      />
      {!contraseñasCoinciden &&
        password.length > 0 &&
        confirmPassword.length > 0 && (
          <Label style={{ color: "red" }} className="text-sm mb-1">
            Las contraseñas no coinciden.
          </Label>
        )}
      <PasswordMinRequired value={password} />

      <Button
        onClick={resetPassword}
        disabled={loading || (!contraseñasCoinciden && !cumpleRequisitos)}
        className="w-full mb-4"
      >
        {loading ? (
          <Loader isLoading={loading}></Loader>
        ) : (
          <span>Recuperar contraseña</span>
        )}
      </Button>
    </Card>
  );
};

export default ForgotSteps;
