export const states = [
  {
    label: "Aguascalientes",
    value: "AGU",
  },
  {
    label: "Baja California",
    value: "BCN",
  },
  {
    label: "Baja California Sur",
    value: "BCS",
  },
  {
    label: "Campeche",
    value: "CAM",
  },
  {
    label: "Chiapas",
    value: "CHP",
  },
  {
    label: "Chihuahua",
    value: "CHH",
  },
  {
    label: "Ciudad de México",
    value: "CMX",
  },
  {
    label: "Coahuila",
    value: "COA",
  },
  {
    label: "Colima",
    value: "COL",
  },
  {
    label: "Durango",
    value: "DUR",
  },
  {
    label: "Guanajuato",
    value: "GUA",
  },
  {
    label: "Guerrero",
    value: "GRO",
  },
  {
    label: "Hidalgo",
    value: "HID",
  },
  {
    label: "Jalisco",
    value: "JAL",
  },
  {
    label: "Estado de México",
    value: "MEX",
  },
  {
    label: "Michoacán",
    value: "MIC",
  },
  {
    label: "Morelos",
    value: "MOR",
  },
  {
    label: "Nayarit",
    value: "NAY",
  },
  {
    label: "Nuevo León",
    value: "NLE",
  },
  {
    label: "Oaxaca",
    value: "OAX",
  },
  {
    label: "Puebla",
    value: "PUE",
  },
  {
    label: "Querétaro",
    value: "QUE",
  },
  {
    label: "Quintana Roo",
    value: "ROO",
  },
  {
    label: "San Luís Potosí",
    value: "SLP",
  },
  {
    label: "Sinaloa",
    value: "SIN",
  },
  {
    label: "Sonora",
    value: "SON",
  },
  {
    label: "Tabasco",
    value: "TAB",
  },
  {
    label: "Tamaulipas",
    value: "TAM",
  },
  {
    label: "Tlaxcala",
    value: "TLA",
  },
  {
    label: "Veracruz",
    value: "VER",
  },
  {
    label: "Yucatán",
    value: "YUC",
  },
  {
    label: "Zacatecas",
    value: "ZAC",
  },
];
