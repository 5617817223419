import { HelpCircle } from "lucide-react";
import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Label } from "../ui/label";

const InputPlaces = ({
  value,
  setValue,
  placeholder = "",
  label,
  withTooltip,
  tooltipText,
  valueFull,
}) => {
  const [data, setData] = useState(null);
  return (
    <>
      {label && (
        <Label>
          {label}
          {withTooltip && (
            <HelpCircle
              data-tooltip-id="my-tooltip"
              data-tooltip-content={tooltipText}
              className="help-icon2"
              fill={"#1A222F"}
              color="white"
            ></HelpCircle>
          )}
        </Label>
      )}
      <GooglePlacesAutocomplete
        apiKey="AIzaSyBOFBkz-9u5DJjwFs0ipNf0RUgTcDEYyFk"
        autocompletionRequest={{
          componentRestrictions: {
            country: ["mx"],
          },
        }}
        selectProps={{
          value: Object.values(valueFull).length ? valueFull : data,
          onChange: (value) => {
            setData(value);
            setValue(value);
          },
          placeholder,
          className:
            "flex h-9 w-full rounded-md border border-input bg-transparent text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
          styles: {
            control: (provided) => ({
              ...provided,
              border: "none",
              boxShadow: "none",
              height: "100%",
              width: "100%",
              minHeight: 0,
              padding: "0vw",
              outline: "none",
            }),
            indicatorsContainer: () => ({
              display: "none",
            }),
            input: (provided) => ({
              ...provided,
              boxShadow: "none",
              border: "none",
              padding: "0",
            }),
            singleValue: (provided) => ({
              ...provided,
            }),
          },
        }}
        apiOptions={{
          language: "es",
          region: "mx",
        }}
      />
    </>
  );
};

export default InputPlaces;
