/* eslint-disable no-unused-vars */
import CustomLoader from "@/common/CustomLoader";
import CustomModal from "@/common/CustomModal";
import Layout from "@/components/layout/AdminLayout";
import MainLayout from "@/components/layout/MainLayout";
import Modal from "@/components/modal";
import NewRequest from "@/components/modal/custom/new-request";
import CustomTable from "@/components/table-custom";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import AceptarCotizacion from "@/modules/PortalCliente/AceptarCotizacion";
import AgendarSesionZoho from "@/modules/PortalCliente/AgendarSesionZoho";
import Request, { BASE_URLS } from "@/utils/fetchUtils";
import useStore from "@/zustand/useStore";
import { DialogTrigger } from "@radix-ui/react-dialog";
import {
  BookCheck,
  Eye,
  FilePen,
  FileUp,
  FolderSync,
  Headset,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Inicio = () => {
  const [loading, setLoading] = useState(false);
  const [modalDetalleSolicitud, setModalDetalleSolicitud] = useState(false);
  const [modalAgendarMeeting, setModalAgendarMeeting] = useState(false);
  const [modalAceptarCotizacion, setModalAceptarCotizacion] = useState(false);
  const [modalNuevaSolicitud, setModalNuevaSolicitud] = useState(false);
  const [modalFirmaContrato, setModalFirmaContrato] = useState(false);
  const [solicitudes, setSolicitudes] = useState([]);
  const [solicitudSeleccionada, setSolicitudSeleccionada] = useState([]);

  const [solicitudesPendientes, setSolicitudesPendientes] = useState([]);
  const [contratosActivos, setContratosActivos] = useState([]);
  const [contratosCerrados, setContratosCerrados] = useState([]);

  const navigate = useNavigate();
  const { setTipoMenu } = useStore();

  const headers = [
    {
      headerName: "Nombre",
      headerSize: "25%",
      field: "nombreSolicitud",
    },
    {
      headerName: "Cantidad",
      headerSize: "20%",
      field: "Valor_del_equipo",
    },
    {
      headerName: "Status",
      headerSize: "20%",
      field: "Stage",
    },
    {
      headerName: "Fecha de inicio",
      headerSize: "20%",
      field: "createdAt",
    },
  ];

  const controlDetalleSolicitud = (solicitudSeleccionada) => {
    setModalDetalleSolicitud(true);
    setSolicitudSeleccionada(solicitudSeleccionada);
  };
  const controlAceptarCotizacion = (solicitudSeleccionada) => {
    setModalAceptarCotizacion(true);
    setSolicitudSeleccionada(solicitudSeleccionada);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const transformDataSolicitudes = async () => {
    setLoading(true);
    const tokenJwt = localStorage.getItem("jwt");
    const resContact = await Request(
      BASE_URLS.GENERAL + "/staging/back-office/user/contact",
      {},
      "GET",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    if (!resContact.ok) {
      setLoading(false);
    }
    if (resContact.status) {
      localStorage.setItem(
        "Solicitudes",
        JSON.stringify(resContact.contact?.Solicitudes)
      );
    }
    const solicitudesFormateadas = resContact.contact?.Solicitudes.map(
      (solicitud) => ({
        ...solicitud,
        acciones: [
          {
            icon: <Eye></Eye>,
            accion: () => controlDetalleSolicitud(solicitud),
            nombre: "Ver Detalle",
          },
          (solicitud.Stage === "Recepcion" ||
            solicitud.Stage === "Carga de Documentos") && {
            icon: <FileUp />,
            accion: () => (
              navigate("/carga-documental"), setTipoMenu("CargaDocumental")
            ),
            nombre: "Cargar Documentos",
          },
          solicitud.Stage === "Visita pendiente" && {
            icon: <Headset />,
            accion: () => setModalAgendarMeeting(true),
            nombre: "Agendar Sesión",
          },
          (solicitud.Stage === "En proceso de análisis" ||
            solicitud.Stage === "En proceso de firma") && {
            icon: <BookCheck />,
            accion: () => controlAceptarCotizacion(solicitud),
            nombre: "Ver Cotización",
          },
          // { icon: <BookCheck  />, accion: () => controlAceptarCotizacion(solicitud), nombre: "Ver Cotización" },
          solicitud.Stage === "En proceso de firma" && {
            icon: <FilePen />,
            accion: () => setModalFirmaContrato(true),
            nombre: "Firmar Contrato",
          },
          // { icon: <FilePen  />, accion: () => setModalFirmaContrato(true), nombre: "Firmar Contrato" },
        ],
        nombreSolicitud: generarNombreSolicitud(solicitud),
        Valor_del_equipo:
          solicitud.Valor_del_equipo !== undefined &&
          formatearDinero(solicitud.Valor_del_equipo),
      })
    );
    setSolicitudes(solicitudesFormateadas);

    setSolicitudesPendientes(
      solicitudesFormateadas?.filter(
        (solicitud) =>
          solicitud.Stage === "Carga de Documentos" ||
          solicitud.Stage === "En revisión" ||
          solicitud.Stage === "Visita agendada" ||
          solicitud.Stage === "Visita pendiente" ||
          solicitud.Stage === "En proceso de análisis" ||
          solicitud.Stage === "En proceso de firma"
      )
    );
    setContratosActivos(
      solicitudesFormateadas?.filter(
        (solicitud) =>
          solicitud.Stage === "Aprobado" ||
          solicitud.Stage === "Comprado" ||
          solicitud.Stage === "Entregado"
      )
    );
    setContratosCerrados(
      solicitudesFormateadas?.filter(
        (solicitud) => solicitud.Stage === "Finalizado"
      )
    );
    setLoading(false);
  };

  const generarNombreSolicitud = (solicitud) => {
    let tipoServicio = solicitud.Tipo_de_servicio;
    let numeroSolicitud = solicitud.solicitudId.slice(12, 19);

    let nombre = "Solicitud ";
    if (tipoServicio === "Leasing") {
      nombre += "Leasing #" + numeroSolicitud;
    } else if (tipoServicio === "Leaseback") {
      nombre += "Leaseback #" + numeroSolicitud;
    } else {
      // Otro tipo de servicio
      nombre += "otro #" + numeroSolicitud;
    }

    return nombre;
  };

  const formatearDinero = (cantidad) => {
    return `$${cantidad?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  useEffect(() => {
    transformDataSolicitudes();
  }, []);

  return (
    <>
      <Layout>
        <CustomLoader isLoading={loading} />
        <div className="py-4">
          <div style={{ width: "100%", padding: 0, overflow: "auto" }}>
            <div className="header-generalInfo"></div>
            <div className="flex justify-between items-center my-2">
              <Label className="text-xl lg:text-4xl">
                Solicitudes pendientes
              </Label>
              <div className="flex gap-x-3">
                <Button onClick={() => setModalNuevaSolicitud(true)}>
                  + Nueva Solicitud
                </Button>
                <Button onClick={() => transformDataSolicitudes()}>
                  <FolderSync></FolderSync>
                </Button>
              </div>
            </div>
            <div className="my-2">
              {solicitudesPendientes?.length === 0 && (
                <Alert>
                  <AlertDescription>
                    No tienes solicitudes pendientes...
                  </AlertDescription>
                </Alert>
              )}
              {solicitudesPendientes?.length > 0 && (
                <CustomTable headers={headers} data={solicitudesPendientes} />
              )}
            </div>
            <Label className="text-xl lg:text-4xl">Contratos activos</Label>
            <div className="my-2">
              {contratosActivos?.length === 0 && (
                <Alert>
                  <AlertDescription>
                    No tienes contratos activos...
                  </AlertDescription>
                </Alert>
              )}
              {contratosActivos?.length > 0 && (
                <CustomTable headers={headers} data={contratosActivos} />
              )}
            </div>
            <Label className="text-xl lg:text-4xl">Contratos cerrados</Label>
            <div>
              {contratosCerrados?.length === 0 && (
                <Alert>
                  <AlertDescription>
                    No tienes contratos cerrados...
                  </AlertDescription>
                </Alert>
              )}
              {contratosCerrados?.length > 0 && (
                <CustomTable headers={headers} data={contratosCerrados} />
              )}
            </div>
          </div>
        </div>
      </Layout>
      <Modal open={modalDetalleSolicitud} setOpen={setModalDetalleSolicitud}>
        <DialogHeader>
          <DialogTitle>Detalle de Solicitud</DialogTitle>
          <DialogDescription>
            Puedes revisar los datos de tu solicitud y el estado en donde se
            encuentra.
          </DialogDescription>
        </DialogHeader>
        <div className="modal-container">
          <div className="detalle-container">
            <div className="detalleIzq">
              <div className="detalle-item">
                <Label className="text-lg font-semibold">
                  Solicitud hecha por:
                </Label>
                <Label className="text-md">
                  {solicitudSeleccionada.Deal_Name}
                </Label>
              </div>
              <div className="detalle-item">
                <Label className="text-lg font-semibold">
                  Cantidad Solicitada:
                </Label>
                <Label className="text-md">
                  {formatearDinero(solicitudSeleccionada.Valor_del_equipo)}
                </Label>
              </div>
              <div className="detalle-item">
                <Label className="text-lg font-semibold">
                  Plazo de Interés:
                </Label>
                <Label className="text-md">
                  {solicitudSeleccionada.Plazo_de_interes}
                </Label>
              </div>
              <div className="detalle-item">
                <Label className="text-lg font-semibold">
                  Descripción de la Solicitud:
                </Label>
                <Label className="text-md">
                  {solicitudSeleccionada.Descripcion_del_equipo}
                </Label>
              </div>
              <div className="detalle-item">
                <Label className="text-lg font-semibold">Creada el día:</Label>
                <Label className="text-md">
                  {formatDate(solicitudSeleccionada.createdAt)}
                </Label>
              </div>
            </div>
            <div className="detalleDer">
              <div className="detalle-item">
                <Label className="text-lg font-semibold">
                  Se encuentra en la etapa:
                </Label>
                <Label className="text-md">
                  {solicitudSeleccionada.Stage === "Recepcion"
                    ? "Recepción"
                    : solicitudSeleccionada.Stage}
                </Label>
              </div>
              <div className="detalle-item">
                <Label className="text-lg font-semibold">
                  Número de Solicitud:
                </Label>
                <Label className="text-md">
                  #{solicitudSeleccionada.solicitudId}
                </Label>
              </div>
              <div className="detalle-item">
                <Label className="text-lg font-semibold">
                  Tipo de Servicio:
                </Label>
                <Label className="text-md">
                  {solicitudSeleccionada.Tipo_de_servicio === "Leasing"
                    ? "Leasing (Arrendamiento)"
                    : solicitudSeleccionada.Tipo_de_servicio}
                </Label>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <DialogTrigger asChild>
            <Button variant="outline">Cerrar</Button>
          </DialogTrigger>
        </DialogFooter>
      </Modal>

      <CustomModal
        open={modalAgendarMeeting}
        setOpen={setModalAgendarMeeting}
        title={"Agenda cita con los Ejecutivos"}
        maxWidth={"80vw"}
        maxHeight={"45vw"}
        withBtnsFooter={false}
        strongTitle={true}
        withCloseTag={true}
        extraText={
          "Para proceder con tu Solicitud es necesario que te pongas en contácto con nosotros para darle seguimiento a tu cotización."
        }
      >
        <AgendarSesionZoho></AgendarSesionZoho>
      </CustomModal>
      <CustomModal
        open={modalAceptarCotizacion}
        setOpen={setModalAceptarCotizacion}
        title={"Acepta tu Cotización"}
        maxWidth={"80vw"}
        maxHeight={"45vw"}
        withBtnsFooter={false}
        strongTitle={true}
        withCloseTag={true}
        extraText={
          "Te hacemos entrega de tu nueva Cotización, para proceder con el contrato de tu solicitud debes aceptarla."
        }
      >
        <AceptarCotizacion
          solicitudSeleccionada={solicitudSeleccionada}
          setModalAceptarCotizacion={setModalAceptarCotizacion}
        ></AceptarCotizacion>
      </CustomModal>
      <CustomModal
        open={modalFirmaContrato}
        setOpen={setModalFirmaContrato}
        title={"Firma tu Contrato"}
        maxWidth={"80vw"}
        maxHeight={"45vw"}
        withBtnsFooter={false}
        strongTitle={true}
        withCloseTag={true}
        extraText={
          "Necesitamos que nos hagas llegar que estas de acuerdo con las especificaciones que conforman tu contrato por medio de tu firma."
        }
      >
        <div style={{ height: "37vw" }}>
          <iframe
            src={
              "https://workdrive.zoho.com/file/7ialiecdc4548a22a4132a5f9a059b1cbbbb3"
            }
            width="100%"
            height="100%"
            style={{ border: "none" }}
            title="Document Viewer"
          />
        </div>
      </CustomModal>
      <NewRequest
        open={modalNuevaSolicitud}
        setOpen={setModalNuevaSolicitud}
        onSave={setModalNuevaSolicitud}
      />
    </>
  );
};

const Root = () => (
  <MainLayout>
    <Inicio />
  </MainLayout>
);

export default Root;
