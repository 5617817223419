import {
  SidebarProvider,
  SidebarTrigger,
  useSidebar,
} from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/app-sidebar";
import { useEffect } from "react";
import { Card } from "../ui/card";
import { Button } from "../ui/button";
import { Menu } from "lucide-react";

export default function Layout({ children }) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (localStorage.getItem("token")) {
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <SidebarProvider>
      <AppSidebar />
      <main className="w-full">
        <div className="lg:hidden">
          <Card className="px-2 rounded-none h-[50px] flex items-center">
            <ButtonToggle />
          </Card>
        </div>
        <div className=" px-4">{children}</div>
      </main>
    </SidebarProvider>
  );
}

const ButtonToggle = () => {
  return <SidebarTrigger />;
};
