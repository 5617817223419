import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import { Label } from "./ui/label";

const InfoDescription = ({ label, info, onClick, pass }) => {
  const [password, setPassword] = useState(false);
  return (
    <div className="flex flex-col items-start" onClick={onClick}>
      <Label className="text-lg">{label}</Label>
      <Label className={`text-md text-gray-600 ${pass ? "flex gap-x-3" : ""}`}>
        {pass ? (
          <>
            {password ? info : "********"}
            {password ? (
              <EyeOff onClick={() => setPassword(!password)} />
            ) : (
              <Eye onClick={() => setPassword(!password)} />
            )}
          </>
        ) : (
          <>{info}</>
        )}
      </Label>
    </div>
  );
};
export default InfoDescription;
