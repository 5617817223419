/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import ItemFolderDocs from "./ItemFolderDocs";
import FlujoMoffin from "./FlujoMoffin";
import Switch from "react-switch";
import Swal from "sweetalert2";
import Request from "../../utils/fetchUtils";
import { useDispatch } from "react-redux";
import { fetchDataFoldersZoho } from "../../redux/actions/generalActions";
import CustomLoader from "../../common/CustomLoader";
import EkatenaRequest from "../../components/items-document/ekatena";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import he from "he";

const SeccionDocumentos = (props) => {
  const { data, gridView, setFolderSelected, checked, setChecked } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChangeSwitchAval = () => {
    Swal.fire({
      icon: "warning",
      title:
        "¿Estás seguro que el Representante Legal será el mismo que el Obligado Solidario/Aval?",
      text: "No te pediremos los documentos del Obligado Solidario/Aval, pero si ya tenías documentos dados de alta, estos se perderán.",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonText: "Estoy seguro",
      showConfirmButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setChecked(!checked);
        localStorage.setItem("avalMismoRL", "Si");
        autorizacionSwitch(!checked);
      }
    });
  };

  const autorizacionSwitch = async (checked) => {
    const tokenJwt = localStorage.getItem("jwt");
    const res = await Request(
      "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/contact",
      {
        contact: {
          Representante_legal_mismo_que_obligado_solidario: checked
            ? "Si"
            : "No", //nuevo
        },
      },
      "PATCH",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    console.log("RESSWITCH", res);
    let timerInterval;
    if (res.ok) {
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "Revise el estatus en su dashboard para más seguimiento",
        html: "Verá reflejado sus cambios en <b></b> segundos.",
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
          }, 10);
        },
      }).then(() => dispatch(fetchDataFoldersZoho(setLoading)));
    } else {
      Swal.fire({
        icon: "error",
        title: "¡Oh no algo salió mal!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  return (
    <Accordion type="single" collapsible>
      <AccordionItem value={data[0].Carpeta_superior}>
        <AccordionTrigger>
          {he.decode(data[0].Carpeta_superior)}
        </AccordionTrigger>
        <AccordionContent>
          <CustomLoader isLoading={loading}></CustomLoader>
          <div className="flex flex-col gap-y-4">
            {data[0].Carpeta_superior ===
              "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" &&
              localStorage.getItem("layout") === "Persona Moral" && (
                <div
                  className="swichAvalRL-container"
                  style={
                    gridView
                      ? {
                          flexDirection: "column",
                          width: "15%",
                          height: "95%",
                          marginRight: "1%",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                      : {}
                  }
                >
                  <div
                    className="switchAvalRL-question"
                    style={
                      gridView
                        ? { textAlign: "center", marginBottom: "1vw" }
                        : {}
                    }
                  >
                    ¿El Obligado Solidario/Aval (OS) es el mismo que el
                    Representante Legal (RL)?
                  </div>
                  <div
                    className="switch-container"
                    style={{
                      margin: "0vw 1vw",
                      display: "flex",
                      color: "white",
                    }}
                  >
                    <Switch
                      className="switch-person"
                      onChange={handleChangeSwitchAval}
                      checked={checked}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onHandleColor={"#4944FC"}
                      offHandleColor={"#6463a7"}
                      offColor="#fff"
                      onColor="#a19eff"
                      borderRadius={5}
                    ></Switch>
                    <div className="switch-label">{checked ? "Si" : "No"}</div>
                  </div>
                </div>
              )}
            {data.map((x) =>
              x.Name ===
                "autorizacion_digital_de_consulta_a_buro_de_credito_os" ||
              x.Name === "autorizacion_digital_de_consulta_a_buro_de_credito" ||
              x.Name ===
                "autorizacion_digital_de_consulta_a_buro_de_credito_RL" ? (
                <FlujoMoffin
                  folderData={x}
                  key={x.id}
                  gridView={gridView}
                  setFolderSelected={setFolderSelected}
                  sameAvalRL={checked}
                ></FlujoMoffin>
              ) : x.Name === "conexion_al_sat_para_informacion_financiera" ? (
                <EkatenaRequest
                  gridView={gridView}
                  folderData={x}
                ></EkatenaRequest>
              ) : (
                <ItemFolderDocs
                  folderData={x}
                  key={x.id}
                  gridView={gridView}
                  setFolderSelected={setFolderSelected}
                  sameAvalRL={checked}
                ></ItemFolderDocs>
              )
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

SeccionDocumentos.propTypes = {};

export default SeccionDocumentos;
