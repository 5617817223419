import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Request, { BASE_URLS } from "../../utils/fetchUtils";
import { useForm } from "../../utils/useForm";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import Divider from "@/components/ui/divider";
import { Loader, Mail } from "lucide-react";
import LogoWhite from "@/assets/logo/LogoWhite.svg";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, newDataChange] = useForm({
    email: "",
    password: "",
  });

  const login = async () => {
    setLoading(true);
    const res = await Request(
      BASE_URLS.GENERAL + "/staging/auth/login",
      {
        email: email,
        password: password,
      },
      "POST"
    );
    if (res.status) {
      const { token: jwt, userAttributes } = res;
      localStorage.setItem("jwt", jwt);
      localStorage.setItem("email", userAttributes.email);
      localStorage.setItem(
        "onboardingCompleted",
        userAttributes.onboardingCompleted
      );
      localStorage.setItem("sUserData", JSON.stringify(userAttributes));
      localStorage.setItem("zohoContactId", userAttributes.zohoContactId);
      const tokenJwt = localStorage.getItem("jwt");
      const resContact = await Request(
        BASE_URLS.GENERAL + "/staging/back-office/user/contact",
        {},
        "GET",
        false,
        false,
        {
          Authorization: `Bearer ${tokenJwt}`,
        }
      );
      if (resContact.status) {
        localStorage.setItem("name", resContact.contact.Full_Name);
        localStorage.setItem("layout", resContact.contact.Layout);
        localStorage.setItem(
          "avalMismoRL",
          resContact.contact.Representante_legal_mismo_que_obligado_solidario
        );
        localStorage.setItem(
          "tipoServicio",
          resContact.contact?.Solicitudes[0]?.Tipo_de_servicio
        );
        localStorage.setItem(
          "Solicitudes",
          JSON.stringify(resContact.contact?.Solicitudes)
        );
      }
      // if (localStorage.getItem("zohoContactId") === "undefined")
      //   setSection("Steps"); aqui se envia para el step en caso no haya completado el proceso
      window.location.href = "/dashboard";
    } else if (res.message === "Incorrect username or password.") {
      Swal.fire({
        icon: "error",
        title: "Usuario o contraseña incorrectos.",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "¡Oh no, algo salió mal! Inténtalo de nuevo",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    setLoading(false);
  };

  const { email, password } = userData;

  return (
    <div className="flex h-screen">
      <div className="w-1/2 bg-gray-900 text-white hidden lg:flex flex-col justify-between p-8">
        <div className="text-xl font-bold">
          <img className="h-5" src={LogoWhite} alt="loginIcon"></img>
        </div>
        <div className="text-sm">
          <blockquote>
            Optimiza el flujo de efectivo de tu PYME con arrendamiento de
            activos ágil y LIBRE DE FRICCIONES.
          </blockquote>
        </div>
      </div>

      <div className="w-full lg:w-1/2 flex items-center justify-center max-sm:px-4">
        <Card className="lg:w-3/4 p-8">
          <img
            className="iconView"
            src="/images/finerik-logo.png"
            alt="loginIcon"
          ></img>
          <Label className="mb-4 text-lg">Inicio de sesión</Label>
          <p className="text-sm mb-4">
            Ingresa tu correo electrónico y contraseña para iniciar sesión
          </p>

          <Label className="mb-1">Correo electrónico</Label>
          <Input
            placeholder="name@example.com"
            type="email"
            value={email}
            name="email"
            onChange={newDataChange}
            className="mb-4"
          />

          <div className="flex items-center justify-between">
            <Label className="mb-1">Contraseña</Label>
            <div
              onClick={() => navigate("/auth/forgot")}
              className="text-sm underline mb-1 cursor-pointer"
            >
              ¿Olvidaste tu contraseña?
            </div>
          </div>
          <Input
            placeholder="********"
            type="password"
            name="password"
            value={password}
            onChange={newDataChange}
            className="mb-4"
          />
          <Button onClick={login} disabled={loading} className="w-full mb-4">
            {loading ? (
              <Loader isLoading={loading}></Loader>
            ) : (
              <span>Iniciar sesión</span>
            )}
          </Button>
          <Divider>O continuar con</Divider>
          <Button
            onClick={() => {
              navigate("/on-boarding");
            }}
            className="w-full mt-4 flex items-center justify-center space-x-2"
          >
            <Mail></Mail>
            <span>Realiza tu pre-aprobación</span>
          </Button>

          <p className="text-xs text-gray-500 mt-4 text-center">
            Al hacer clic en continuar, usted acepta nuestros{" "}
            <a
              href="https://www.finerik.com/terminos-y-condiciones"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 underline"
            >
              Terminos y condiciones
            </a>{" "}
            y{" "}
            <a
              href="https://www.finerik.com/aviso-de-privacidad"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 underline"
            >
              Politicas de privacidad
            </a>
            .
          </p>
        </Card>
      </div>
    </div>
  );
};

export default Login;
